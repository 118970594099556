import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  careerBlog: {},
  careerSingleBlog: {},
  adding: false,
};

const careerBlogSlice = createSlice({
  name: "careerBlog",
  initialState,
  reducers: {
    getCareerBlog: (state, action) => {
      state.loading = true;
    },
    getCareerBlogSuccess: (state, action) => {
      state.loading = false;
      state.careerBlog = action.payload;
    },
    getCareerBlogFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    getCareerBlogSingle: (state, action) => {
      state.loading = true;
    },
    getCareerBlogSingleSuccess: (state, action) => {
      state.loading = false;
      state.careerSingleBlog = action.payload;
    },
    getCareerBlogSingleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addCareerBlog: (state, action) => {},
    deleteCareerBlog: (state, action) => {
      state.adding = true;
    },
    deleteCareerBlogSuccess: (state, action) => {
      state.adding = false;
    },
    deleteCareerBlogFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getCareerBlog,
  getCareerBlogSuccess,
  getCareerBlogFailure,
  getCareerBlogSingle,
  getCareerBlogSingleSuccess,
  getCareerBlogSingleFailure,
  addCareerBlog,
  deleteCareerBlog,
  deleteCareerBlogFailure,
  deleteCareerBlogSuccess,
} = careerBlogSlice.actions;

export default careerBlogSlice.reducer;
