import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passageList: [],
  loading: false,
  singleLoading: true,
  error: "",
  singlePassage: {},
  adding: false,
};

const passageSlice = createSlice({
  name: "passage",
  initialState: initialState,
  reducers: {
    storePassageData: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getPassageList: (state, action) => {
      state.loading = true;
    },
    getPassageListSuccess: (state, action) => {
      state.passageList = action.payload;
      state.loading = false;
    },
    getPassageListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addPassageData: (state, action) => {},
    getSinglePassage: (state, action) => {
      state.singleLoading = true;
    },
    getSinglePassageSuccess: (state, action) => {
      state.singlePassage = action.payload;
      state.singleLoading = false;
    },
    getSinglePassageFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleLoading = false;
    },
    deletePassage: (state, action) => {
      state.adding = true;
    },
    deletePassageSuccess: (state, action) => {
      state.adding = false;
    },
    deletePassageFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getPassageList,
  getPassageListSuccess,
  getPassageListFailure,
  addPassageData,
  getSinglePassage,
  getSinglePassageSuccess,
  getSinglePassageFailure,
  deletePassage,
  deletePassageSuccess,
  deletePassageFailure,
  storePassageData,
} = passageSlice.actions;

export default passageSlice.reducer;
