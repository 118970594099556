import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  serviceSalesSummary: {},
  salesList: {},
  salesDetails: {},
  salesReport: {},
  reportService: {},
  adding: false,
};

const salesListSlice = createSlice({
  name: "salesList",
  initialState,
  reducers: {
    getServiceSalesSummary: (state, action) => {
      state.loading = true;
    },
    getServiceSalesSummarySuccess: (state, action) => {
      state.loading = false;
      state.serviceSalesSummary = action.payload;
    },
    getServiceSalesSummaryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    getSalesList: (state, action) => {
      state.loading = true;
    },
    getSalesListSuccess: (state, action) => {
      state.loading = false;
      state.salesList = action.payload;
    },
    getSalesListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    getSalesReport: (state, action) => {
      state.loading = true;
    },
    getSalesReportSuccess: (state, action) => {
      state.loading = false;
      state.salesReport = action.payload;
    },
    getSalesReportFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    getSalesDetails: (state, action) => {
      state.loading = true;
    },
    getSalesDetailsSuccess: (state, action) => {
      state.loading = false;
      state.salesDetails = action.payload;
    },
    getSalesDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    getReportService: (state, action) => {
      state.loading = true;
    },
    getReportServiceSuccess: (state, action) => {
      state.loading = false;
      state.reportService = action.payload;
    },
    getReportServiceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addMentor: (state, action) => {},
  },
});

export const {
  getSalesList,
  getSalesListSuccess,
  getSalesListFailure,
  getSalesReport,
  getSalesReportSuccess,
  getSalesReportFailure,
  getSalesDetails,
  getSalesDetailsSuccess,
  getSalesDetailsFailure,
  addMentor,
  getServiceSalesSummary,
  getServiceSalesSummarySuccess,
  getServiceSalesSummaryFailure,
  getReportService,
  getReportServiceSuccess,
  getReportServiceFailure,
} = salesListSlice.actions;

export default salesListSlice.reducer;
