import { call, put, takeEvery } from "redux-saga/effects";
import { get, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addCvScoring,
  getCvScoring,
  getCvScoringFailure,
  getCvScoringSuccess,
  reRenderCvScoring,
} from "./CvScoringSlice";

// add or update acvScoring
function* onAddCvScoring({
  payload: {
    data,
    options: { setSubmitting, resetForm, id },
  },
}) {
  try {
    const url = `/resume-scoring-setup/create-or-update`;
    const response = yield call(post, url, data);
    if (response) {
      toaster("success", `CvScoring ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(reRenderCvScoring());
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `CvScoring ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// get all cvScoring
function* onGetCvScoring() {
  try {
    const url = `/resume-scoring-setup/get-resume-scoring-data`;
    const response = yield call(get, url);
    // console.log(`response:`, response);
    if (response) {
      yield put(getCvScoringSuccess(response?.data));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get cvScoring failed";
    yield put(getCvScoringFailure({ message }));
  }
}

export function* cvScoringSaga() {
  yield takeEvery(getCvScoring.type, onGetCvScoring);
  yield takeEvery(addCvScoring.type, onAddCvScoring);
}
