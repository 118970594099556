import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getMeetingScheduleFailure,
  getMeetingScheduleSuccess,
  getMeetingSchedules,
} from "./MeetingScheduleSlice";

// get all meeting schedule
function* onGetMeetingSchedule({ payload: { date = "", employeeId = "" } }) {
  try {
    const url = `/meeting-schedule/data/?date=${date}&employeeId=${employeeId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getMeetingScheduleSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get meeting schedule failed";
    yield put(getMeetingScheduleFailure({ message }));
  }
}

export function* meetingScheduleMentorSaga() {
  yield takeEvery(getMeetingSchedules.type, onGetMeetingSchedule);
}
