import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  mentorList: {},
  adding: false,
};

const mentorListSlice = createSlice({
  name: "mentorList",
  initialState,
  reducers: {
    getMentorList: (state, action) => {
      state.loading = true;
    },
    getMentorListSuccess: (state, action) => {
      state.loading = false;
      state.mentorList = action.payload;
    },
    getMentorListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addMentorList: (state, action) => {},
    deleteMentorList: (state, action) => {
      state.adding = true;
    },
    deleteMentorListSuccess: (state, action) => {
      state.adding = false;
    },
    deleteMentorListFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getMentorList,
  getMentorListSuccess,
  getMentorListFailure,
  addMentorList,
  deleteMentorList,
  deleteMentorListFailure,
  deleteMentorListSuccess,
} = mentorListSlice.actions;

export default mentorListSlice.reducer;
