import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addCommonData,
  getCommonData,
  getCommonDataFailure,
  getCommonDataSuccess,
} from "./CommonDataSlice";

// get all Common Data
function* onGetCommonData({ payload: { type } }) {
  try {
    const url = `/common-data/data?type=${type}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCommonDataSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Get ${type} failed`;
    yield put(getCommonDataFailure({ message }));
  }
}

// add a CommonData
function* onAddCommonData({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/common-data/${id}` : `/common-data`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `${data?.type || "Data"} ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${data?.type || "Data"} ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

export function* commonDataSaga() {
  yield takeEvery(getCommonData.type, onGetCommonData);
  yield takeEvery(addCommonData.type, onAddCommonData);
}
