import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addCareerBlog,
  deleteCareerBlog,
  deleteCareerBlogFailure,
  deleteCareerBlogSuccess,
  getCareerBlog,
  getCareerBlogFailure,
  getCareerBlogSingle,
  getCareerBlogSingleFailure,
  getCareerBlogSingleSuccess,
  getCareerBlogSuccess,
} from "./CareerBlogSlice";

// get all career Blog
function* onGetCareerBlog({
  payload: {
    page = 0,
    limit = 0,
    adviceCategoryId = "",
    author = "",
    status = "",
  },
}) {
  try {
    const url = `/career-advice/data/?limit=${limit}&pageNo=${page}&adviceCategoryId=${adviceCategoryId}&author=${author}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCareerBlogSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Career advice blog failed";
    yield put(getCareerBlogFailure({ message }));
  }
}

// get single career Blog
function* onGetSingleCareerBlog({ payload: { id = "" } }) {
  try {
    const url = `/career-advice/details/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCareerBlogSingleSuccess(response?.careerAdvice));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single Career advice blog failed";
    yield put(getCareerBlogSingleFailure({ message }));
  }
}

// add a career blog
function* onAddCareerBlog({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/career-advice/${id}` : `/career-advice`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Career advice blog ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Career advice blog ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// delete a career blog
function* onDeleteCareerBlog({ payload: { id, toggle } }) {
  try {
    const url = `/career-advice/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteCareerBlogSuccess(response));
      toaster("success", "Career advice blog deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Career advice blog deleted failed";
    toaster("error", message);
    yield put(deleteCareerBlogFailure({ message }));
  }
}

export function* careerBlogSaga() {
  yield takeEvery(getCareerBlog.type, onGetCareerBlog);
  yield takeEvery(getCareerBlogSingle.type, onGetSingleCareerBlog);
  yield takeEvery(addCareerBlog.type, onAddCareerBlog);
  yield takeEvery(deleteCareerBlog.type, onDeleteCareerBlog);
}
