import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  testimonial: {},
  adding: false,
};

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {
    getTestimonial: (state, action) => {
      state.loading = true;
    },
    getTestimonialSuccess: (state, action) => {
      state.loading = false;
      state.testimonial = action.payload;
    },
    getTestimonialFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addTestimonial: (state, action) => {},
    deleteTestimonial: (state, action) => {
      state.adding = true;
    },
    deleteTestimonialSuccess: (state, action) => {
      state.adding = false;
    },
    deleteTestimonialFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getTestimonial,
  getTestimonialSuccess,
  getTestimonialFailure,
  addTestimonial,
  deleteTestimonial,
  deleteTestimonialFailure,
  deleteTestimonialSuccess,
} = testimonialSlice.actions;

export default testimonialSlice.reducer;
