import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import { login } from "../../Auth/Login/loginSlice";
import {
  getProfileUser,
  getProfileUserFailure,
  getProfileUserSuccess,
  updateProfileUser,
  updateUserPassword,
  userForgotPassword,
  userResetPassword,
} from "./ProfileUserSlice";

function* onGetUserProfile({ payload: { id } }) {
  try {
    const url = `/applicant-panel/profile/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getProfileUserSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get profile failed";
    yield put(getProfileUserFailure({ message }));
  }
}

function* onUpdateUserProfile({
  payload: { data, setSubmitting, id, toggle },
}) {
  try {
    const url = `/applicant-panel/profile/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", "Update profile successful");
      setSubmitting && setSubmitting(false);
      toggle && toggle(true);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Update profile Failed";
    toaster("error", message);
    setSubmitting && setSubmitting(false);
  }
}

function* onUpdateUserPassword({ payload: { data, setSubmitting, toggle } }) {
  try {
    const url = `/career-applicant-auth/updateMyPassword`;
    const response = yield call(patch, url, data);
    // console.log(`response`, response);
    if (response) {
      toaster("success", "Update profile successful");
      setSubmitting && setSubmitting(false);
      toggle && toggle(true);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Update profile Failed";
    toaster("error", message);
    setSubmitting && setSubmitting(false);
  }
}

function* onUserForgotPassword({
  payload: {
    data,
    options: { toggle, resetForm, setSubmitting },
  },
}) {
  try {
    const url = `/applicant-auth/forgot-password`;
    const response = yield call(post, url, data);

    if (response) {
      // toaster("success", `Token send your email, please check your email`);
      toggle(true);
      yield setSubmitting(false);
      yield resetForm();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Token send failed, please try again`;
    toaster("error", message);
    toggle(false);
    yield setSubmitting(false);
  }
}

function* onUserResetPassword({
  payload: {
    data,
    options: { token, history, setSubmitting, resetForm },
  },
}) {
  try {
    const url = `/applicant-auth/reset-password/${token}`;
    const response = yield call(patch, url, data);

    if (response) {
      toaster("success", `Change password successfully`);

      yield put(
        login({
          history,
          data: {
            userName: response?.user?.email,
            password: data?.password,
          },
          url: "/applicant-auth/login",
          redirect: "/user/profile",
        })
      );
      yield setSubmitting(false);
      yield resetForm();
    }
  } catch (error) {
    yield setSubmitting(false);
    const message =
      error?.response?.data?.message ||
      `Change password failed, please try again`;
    toaster("error", message);
  }
}

export function* profileUserSaga() {
  yield takeEvery(getProfileUser.type, onGetUserProfile);
  yield takeEvery(updateProfileUser.type, onUpdateUserProfile);
  yield takeEvery(updateUserPassword.type, onUpdateUserPassword);
  yield takeEvery(userForgotPassword.type, onUserForgotPassword);
  yield takeEvery(userResetPassword.type, onUserResetPassword);
}
