import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topFeedbackSubmit: {},
  feedbackDepartment: {},
  feedbackDesignation: {},
  topAchiever: {},
  topFeedbackSubmitLoading: false,
  feedbackDepartmentLoading: false,
  feedbackDesignationLoading: false,
  topAchieverLoading: false,
  error: "",
};

const islamGroupDashboardSlice = createSlice({
  name: "islamGroupDashboard",
  initialState,
  reducers: {
    getTopFeedbackSubmit: (state) => {
      state.topFeedbackSubmitLoading = true;
    },
    getTopFeedbackSubmitSuccess: (state, action) => {
      state.topFeedbackSubmitLoading = false;
      state.topFeedbackSubmit = action.payload;
    },
    getTopFeedbackSubmitFail: (state, action) => {
      state.topFeedbackSubmitLoading = false;
      state.error = action.payload?.message;
    },
    getFeedbackByDepartment: (state) => {
      state.feedbackDepartmentLoading = true;
    },
    getFeedbackByDepartmentSuccess: (state, action) => {
      state.feedbackDepartmentLoading = false;
      state.feedbackDepartment = action.payload;
    },
    getFeedbackByDepartmentFail: (state, action) => {
      state.feedbackDepartmentLoading = false;
      state.error = action.payload?.message;
    },
    getFeedbackByDesignation: (state) => {
      state.feedbackDesignationLoading = true;
    },
    getFeedbackByDesignationSuccess: (state, action) => {
      state.feedbackDesignationLoading = false;
      state.feedbackDesignation = action.payload;
    },
    getFeedbackByDesignationFail: (state, action) => {
      state.feedbackDesignationLoading = false;
      state.error = action.payload?.message;
    },
    getTopAchiever: (state) => {
      state.topAchieverLoading = true;
    },
    getTopAchieverSuccess: (state, action) => {
      state.topAchieverLoading = false;
      state.topAchiever = action.payload;
    },
    getTopAchieverFail: (state, action) => {
      state.topAchieverLoading = false;
      state.error = action.payload?.message;
    },
  },
});

export const {
  getTopFeedbackSubmit,
  getTopFeedbackSubmitSuccess,
  getTopFeedbackSubmitFail,
  getFeedbackByDepartment,
  getFeedbackByDepartmentSuccess,
  getFeedbackByDepartmentFail,
  getFeedbackByDesignation,
  getFeedbackByDesignationSuccess,
  getFeedbackByDesignationFail,
  getTopAchiever,
  getTopAchieverSuccess,
  getTopAchieverFail,
} = islamGroupDashboardSlice.actions;

export default islamGroupDashboardSlice.reducer;
