import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  bannerSection: {},
  adding: false,
};

const bannerSectionSlice = createSlice({
  name: "bannerSection",
  initialState,
  reducers: {
    getBannerSection: (state, action) => {
      state.loading = true;
    },
    getBannerSectionSuccess: (state, action) => {
      state.loading = false;
      state.bannerSection = action.payload;
    },
    getBannerSectionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addBannerSection: (state, action) => {},
    deleteBannerSection: (state, action) => {
      state.adding = true;
    },
    deleteBannerSectionSuccess: (state, action) => {
      state.adding = false;
    },
    deleteBannerSectionFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getBannerSection,
  getBannerSectionSuccess,
  getBannerSectionFailure,
  addBannerSection,
  deleteBannerSection,
  deleteBannerSectionFailure,
  deleteBannerSectionSuccess,
} = bannerSectionSlice.actions;

export default bannerSectionSlice.reducer;
