import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";

export const SocketContext = createContext();
const url =
  // "http://localhost:5050" ||
  process.env.REACT_APP_SOCKET_URL;
const socket = io(url);

const SocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  console.log(`isConnected>>>>>`, isConnected);
  const [lastPong, setLastPong] = useState(null);

  useEffect(() => {
    // console.log("Render????");
    socket.on("connect", () => {
      console.log("connected");
      setIsConnected(true);
    });
    socket.on("disconnect", () => {
      console.log("disconnected");
      setIsConnected(false);
    });
    socket.on("pong", () => {
      setLastPong(new Date().toISOString());
    });
    socket.emit("ConnectToNotification");
    return () => {
      // socket.emit("disconnect", user?._id);
      // socket.off("connect", user?._id);
      // socket.off("pong", user?._id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendPing = () => {
    socket.emit("ping");
  };

  return (
    <React.Fragment>
      <SocketContext.Provider
        value={{ socket, isConnected, lastPong, sendPing }}
      >
        {children}
      </SocketContext.Provider>
    </React.Fragment>
  );
};

export default SocketProvider;
