import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  commonData: {},
};

const commonDataSlice = createSlice({
  name: "commonData",
  initialState,
  reducers: {
    getCommonData: (state, action) => {
      state.loading = true;
    },
    getCommonDataSuccess: (state, action) => {
      state.loading = false;
      state.commonData = action.payload;
    },
    getCommonDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addCommonData: (state, action) => {},
  },
});

export const {
  getCommonData,
  getCommonDataSuccess,
  getCommonDataFailure,
  addCommonData,
} = commonDataSlice.actions;

export default commonDataSlice.reducer;
