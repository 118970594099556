import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //   loading: false,
  //   error: "",
  cvScrolling: "",
  cvData: {},
  textData: "",
  //   adding: false,
};

const cvScrollingSlice = createSlice({
  name: "cvScrolling",
  initialState: initialState,
  reducers: {
    uploadCV: (state, action) => {
      state.cvScrolling = action.payload?.cv;
      state.cvData = action.payload?.cvData;
      state.textData = action.payload?.textData;
    },
  },
});

export const { uploadCV } = cvScrollingSlice.actions;

export default cvScrollingSlice.reducer;
