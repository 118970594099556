import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  taskList: {},
  taskDetails: {},
};

const taskListSlice = createSlice({
  name: "taskList",
  initialState: initialState,
  reducers: {
    getTaskLists: (state, action) => {
      state.loading = true;
    },
    getTaskListSuccess: (state, action) => {
      state.taskList = action.payload;
      state.loading = false;
    },
    getTaskListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addTaskList: (state, action) => {},
    getTaskDetails: (state, action) => {
      state.loading = true;
    },
    getTaskDetailsSuccess: (state, action) => {
      state.taskDetails = action.payload;
      state.loading = false;
    },
    getTaskDetailsFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
  },
});

export const {
  getTaskLists,
  getTaskListSuccess,
  getTaskListFailure,
  addTaskList,
  getTaskDetails,
  getTaskDetailsSuccess,
  getTaskDetailsFailure,
} = taskListSlice.actions;

export default taskListSlice.reducer;
