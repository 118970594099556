import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const scrollingDataSlice = createSlice({
  name: "scrollingData",
  initialState,
  reducers: {
    addScrollingData: (state, action) => {},
  },
});

export const { addScrollingData } = scrollingDataSlice.actions;

export default scrollingDataSlice.reducer;
