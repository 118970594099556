import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  meetingSchedule: [],
  timeSlots: [],
  adding: false,
};

const addMeetingScheduleSlice = createSlice({
  name: "addMeetingSchedule",
  initialState,
  reducers: {
    storeSchedule: (state, { payload }) => {
      state[payload.name] = payload?.data;
    },
    getMeetingSchedule: (state, action) => {
      state.loading = true;
    },
    getMeetingScheduleSuccess: (state, { payload }) => {
      const { meetingSchedules, timeSlots } = payload;

      timeSlots.forEach((el) => {
        let findSlot = meetingSchedules?.find(
          (item) => item?.timeSlotId?._id === el?._id
        );

        el.meetingLink = findSlot?.meetingLink;
        el.date = findSlot?.date;
        if (findSlot) {
          el.timeSlotId = findSlot?.timeSlotId;
          el.selected = true;
          el.isAdded = true;
        } else {
          el.selected = false;
          el.isAdded = false;
        }
      });

      state.loading = false;
      state.meetingSchedule = meetingSchedules;
      state.timeSlots = timeSlots;
    },
    getMeetingScheduleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addMeetingSchedule: (state, action) => {},
    deleteMeetingSchedule: (state, action) => {
      state.adding = true;
    },
    deleteMeetingScheduleSuccess: (state, action) => {
      state.adding = false;
    },
    deleteMeetingScheduleFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  storeSchedule,
  getMeetingSchedule,
  getMeetingScheduleSuccess,
  getMeetingScheduleFailure,
  addMeetingSchedule,
  deleteMeetingSchedule,
  deleteMeetingScheduleFailure,
  deleteMeetingScheduleSuccess,
} = addMeetingScheduleSlice.actions;

export default addMeetingScheduleSlice.reducer;
