import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  messageLoading: false,
  statusLoading: false,
  error: "",
  purchasesService: {},
  purchasesServiceDetails: {},
  userStatus: {},
  messages: {},
  adding: false,
};

const purchasesServiceSlice = createSlice({
  name: "purchasesService",
  initialState,
  reducers: {
    getPurchasesService: (state, action) => {
      state.loading = true;
    },
    getPurchasesServiceSuccess: (state, action) => {
      state.loading = false;
      state.purchasesService = action.payload;
    },
    getPurchasesServiceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    getPurchasesServiceDetails: (state, action) => {
      state.loading = true;
    },
    getPurchasesServiceDetailsSuccess: (state, action) => {
      state.loading = false;
      state.purchasesServiceDetails = action.payload;
    },
    getPurchasesServiceDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addPurchasesService: (state, action) => {},
    addPurchasesRating: (state, action) => {},

    loadMessage: (state, action) => {
      state.messageLoading = true;
    },
    loadMessageSuccess: (state, action) => {
      state.messageLoading = false;
      state.messages = action.payload;
    },
    loadMessageFailure: (state, action) => {
      state.messageLoading = false;
      state.error = action.payload.message;
    },
    getUserActiveStatus: (state, action) => {
      state.statusLoading = true;
    },
    getUserActiveStatusSuccess: (state, action) => {
      state.statusLoading = false;
      state.userStatus = action.payload;
    },
    getUserActiveStatusFailure: (state, action) => {
      state.statusLoading = false;
      state.error = action.payload.message;
    },
  },
});

export const {
  getPurchasesService,
  getPurchasesServiceSuccess,
  getPurchasesServiceFailure,
  getPurchasesServiceDetails,
  getPurchasesServiceDetailsSuccess,
  getPurchasesServiceDetailsFailure,
  addPurchasesService,
  addPurchasesRating,
  loadMessage,
  loadMessageSuccess,
  loadMessageFailure,
  getUserActiveStatus,
  getUserActiveStatusSuccess,
  getUserActiveStatusFailure,
} = purchasesServiceSlice.actions;

export default purchasesServiceSlice.reducer;
