import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  singleUser: {},
  currentTab: "Info",
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialState,
  reducers: {
    storeEmployeeProfile: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    resetEmployee: (state, action) => {
      state.loading = false;
      state.error = "";
      state.singleUser = {};
      state.currentTab = "Info";
    },
    getSingleUser: (state, action) => {
      state.loading = true;
    },
    getSingleUserSuccess: (state, action) => {
      state.singleUser = action.payload;
      state.loading = false;
    },
    getSingleUserFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    editSingleUser: (state, action) => {},
  },
});

export const {
  getSingleUser,
  getSingleUserSuccess,
  getSingleUserFailure,
  editSingleUser,
  storeEmployeeProfile,
  resetEmployee,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
