import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addFeedback,
  addFeedbackFailure,
  addFeedbackSuccess,
  addIGDepartment,
  addIGDesignation,
  deleteIGDepartment,
  deleteIGDepartmentFailure,
  deleteIGDepartmentSuccess,
  deleteIGDesignation,
  deleteIGDesignationFailure,
  deleteIGDesignationSuccess,
  getFeedbackFormCount,
  getFeedbackFormCountFail,
  getFeedbackFormCountSuccess,
  getFeedbackFormData,
  getFeedbackFormDataFail,
  getFeedbackFormDataSuccess,
  getIGDepartment,
  getIGDepartmentFail,
  getIGDepartmentSuccess,
  getIGDesignation,
  getIGDesignationFail,
  getIGDesignationSuccess,
  getIGSingleDepartment,
  getIGSingleDepartmentFail,
  getIGSingleDepartmentSuccess,
} from "./islamGroupSlice";

function* onGetDepartment({ payload: { limit = 0, page = 0 } }) {
  try {
    const url = `/department/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getIGDepartmentSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get department data failed!";
    yield put(getIGDepartmentFail({ message }));
  }
}

function* onGetSingleDepartment({ payload: { id } }) {
  try {
    const url = `/department/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getIGSingleDepartmentSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single department data failed!";
    yield put(getIGSingleDepartmentFail({ message }));
  }
}

function* onAddIGDepartment({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id, history },
  },
}) {
  try {
    const url = id ? `/department/${id}` : `/department`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Department ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      history.push("/admin/department");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Department failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onDeleteIGDepartment({ payload: { id, toggle } }) {
  try {
    const url = `/department/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteIGDepartmentSuccess(response));
      toaster("success", "Department deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete department failed";
    toaster("error", message);
    yield put(deleteIGDepartmentFailure({ message }));
  }
}

function* onGetDesignation({ payload: { limit, page } }) {
  try {
    const url = `/designation/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getIGDesignationSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get designation data failed!";
    yield put(getIGDesignationFail({ message }));
  }
}

function* onAddIGDesignation({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/designation/${id}` : `/designation`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Designation ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} designation failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onDeleteIGDesignation({ payload: { id, toggle } }) {
  try {
    const url = `/designation/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteIGDesignationSuccess(response));
      toaster("success", "Designation deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete designation failed";
    toaster("error", message);
    yield put(deleteIGDesignationFailure({ message }));
  }
}

function* onAddFeedbackSubmit({
  payload: {
    data,
    options: { toggle },
  },
}) {
  try {
    const url = "/users/feedback-submit";
    const response = yield call(post, url, data);
    if (response) {
      toaster("success", `Feedback submitted successfully`);
      toggle(true);
      yield put(addFeedbackSuccess());
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Feedback submitted failed`;
    toaster("error", message);
    yield put(addFeedbackFailure({ message }));
    toggle();
  }
}

function* onGetFeedbackForm({
  payload: {
    department = "",
    designation = "",
    limit = 0,
    page = 0,
    query = "",
  },
}) {
  try {
    const url = `/users/get-islam-group-data?department=${department}&designation=${designation}&limit=${limit}&pageNo=${page}${query}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getFeedbackFormDataSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get feedback form data failed!";
    yield put(getFeedbackFormDataFail({ message }));
  }
}

function* onGetFeedbackFormCount({
  payload: { department = "", designation = "", query = "" },
}) {
  try {
    const url = `/users/get-islam-group-counting-data?department=${department}&designation=${designation}${query}`;
    const response = yield call(post, url);
    if (response) {
      yield put(getFeedbackFormCountSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get feedback count data failed!";
    yield put(getFeedbackFormCountFail({ message }));
  }
}

export function* islamGroupSaga() {
  yield takeEvery(getIGDepartment.type, onGetDepartment);
  yield takeEvery(getIGSingleDepartment.type, onGetSingleDepartment);
  yield takeEvery(addIGDepartment.type, onAddIGDepartment);
  yield takeEvery(deleteIGDepartment.type, onDeleteIGDepartment);
  yield takeEvery(getIGDesignation.type, onGetDesignation);
  yield takeEvery(addIGDesignation.type, onAddIGDesignation);
  yield takeEvery(deleteIGDesignation.type, onDeleteIGDesignation);
  yield takeEvery(addFeedback.type, onAddFeedbackSubmit);
  yield takeEvery(getFeedbackFormData.type, onGetFeedbackForm);
  yield takeEvery(getFeedbackFormCount.type, onGetFeedbackFormCount);
}
