import { call, takeEvery } from "redux-saga/effects";
import { post } from "../../../helpers/api_helper";
import { addScrollingData } from "./CVScrollingDataSlice";

// add a purchases service
function* onAddScrollingData({ payload: { data } }) {
  try {
    const url = `/applicant-panel/resume-scoring`;
    const response = yield call(post, url, data);
    if (response) {
      // toaster("success", `CV scrolling data added successfully`);
    }
  } catch (error) {
    // const message =
    //   error?.response?.data?.message || `CV scrolling data added failed`;
    // toaster("error", message);
  }
}

export function* scrollingDataSaga() {
  yield takeEvery(addScrollingData.type, onAddScrollingData);
}
