import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  editSingleUser,
  getSingleUser,
  getSingleUserFailure,
  getSingleUserSuccess,
} from "./userProfileSlice";

function* onGetSingleUser({ payload: { id } }) {
  try {
    const url = `/employee-panel/profile/${id}`;
    const response = yield call(get, url);
    // console.log(response);
    if (response) {
      yield put(getSingleUserSuccess(response?.myProfile));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get User failed";
    yield put(getSingleUserFailure({ message }));
  }
}

function* onEditSingleUser({ payload: { id, data, toggle, setSubmitting } }) {
  try {
    const url = `/employee-panel/profile/${id}`;
    const response = yield call(patch, url, data);

    if (response) {
      toaster("success", `Updated user successfully`);
      yield setSubmitting(false);
      yield toggle(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Updated user failed`;
    toaster("error", message);
  }
}

export function* userProfileSaga() {
  yield takeEvery(getSingleUser.type, onGetSingleUser);
  yield takeEvery(editSingleUser.type, onEditSingleUser);
}
