import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  careerServices: {},
  careerSingleService: {},
  adding: false,
};

const careerServicesSlice = createSlice({
  name: "careerServices",
  initialState,
  reducers: {
    getCareerServices: (state, action) => {
      state.loading = true;
    },
    getCareerServicesSuccess: (state, action) => {
      state.loading = false;
      state.careerServices = action.payload;
    },
    getCareerServicesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    getCareerSingleServices: (state, action) => {
      state.loading = true;
    },
    getCareerSingleServicesSuccess: (state, action) => {
      state.loading = false;
      state.careerSingleService = action.payload;
    },
    getCareerSingleServicesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addCareerServices: (state, action) => {},
    deleteCareerServices: (state, action) => {
      state.adding = true;
    },
    deleteCareerServicesSuccess: (state, action) => {
      state.adding = false;
    },
    deleteCareerServicesFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getCareerServices,
  getCareerServicesSuccess,
  getCareerServicesFailure,
  getCareerSingleServices,
  getCareerSingleServicesSuccess,
  getCareerSingleServicesFailure,
  addCareerServices,
  deleteCareerServices,
  deleteCareerServicesFailure,
  deleteCareerServicesSuccess,
} = careerServicesSlice.actions;

export default careerServicesSlice.reducer;
