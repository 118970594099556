import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  applicantList: {},
  loading: false,
  singleApplicantLoading: false,
  error: "",
  singleApplicant: {},
  adding: false,
};

const applicantSlice = createSlice({
  name: "applicant",
  initialState: initialState,
  reducers: {
    getApplicantList: (state, action) => {
      state.loading = true;
    },
    getApplicantListSuccess: (state, action) => {
      // console.log(`action.payload:`, action.payload);
      state.applicantList = action.payload;
      state.loading = false;
    },
    getApplicantListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addApplicantData: (state, action) => {},
    getSingleApplicant: (state, action) => {
      state.singleApplicantLoading = true;
    },
    getSingleApplicantSuccess: (state, action) => {
      state.singleApplicant = action.payload;
      state.singleApplicantLoading = false;
    },
    getSingleApplicantFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleApplicantLoading = false;
    },
    deleteApplicant: (state, action) => {
      state.adding = true;
    },
    deleteApplicantSuccess: (state, action) => {
      state.adding = false;
    },
    deleteApplicantFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getApplicantList,
  getApplicantListSuccess,
  getApplicantListFailure,
  addApplicantData,
  getSingleApplicant,
  getSingleApplicantSuccess,
  getSingleApplicantFailure,
  deleteApplicant,
  deleteApplicantSuccess,
  deleteApplicantFailure,
} = applicantSlice.actions;

export default applicantSlice.reducer;
