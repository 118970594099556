import { call, put, takeEvery } from "redux-saga/effects";
import { axiosApi, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  logOutUser,
  login,
  loginFailure,
  loginSuccess,
  mentorForgetPassword,
  mentorResetPassword,
  registerApplicant,
  updateApplicantPassword,
  updateEmployeePassword,
} from "./loginSlice";

function* onLoginUser({
  payload: { history, data, url = "/admin-auth/login", redirect = "/" },
}) {
  try {
    const response = yield call(post, url, data);

    if (response) {
      const role = response?.user?.role || response.userType;

      // console.log({ x: response?.user?.role, Y: response.userType });

      yield put(
        loginSuccess({
          token: response?.token,
          role: role,
          roles: [],
          user: response?.user,
        })
      );
      axiosApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response?.token}`;
      toaster("success", "Login Successful");

      // console.log({ redirect });
      history.push(
        role === "Islam Group" ? "/admin/islam-group-dashboard" : redirect
      );
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Login Failed";
    toaster("error", message);
    yield put(loginFailure({ message }));
  }
}

function* onRegister({ payload: { history, data, setSubmitting } }) {
  try {
    const url = "/applicant-panel/profile/applicant-sign-up";
    const response = yield call(post, url, data);
    // console.log(`response`, response);
    if (response) {
      toaster("success", "Successful! Check your email");
      setSubmitting && setSubmitting(false);
      history.push(`/user/login`);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Registration Failed";
    toaster("error", message);
    yield put(loginFailure({ message }));
    setSubmitting && setSubmitting(false);
  }
}

function* onLogOutUser({ payload: { history, role } }) {
  if (role === "applicant") {
    yield history.push("/user/login");
  } else if (role === "employee") {
    yield history.push("/mentor/login");
  } else if (role === "ADMIN") {
    yield history.push("/admin/login");
  } else {
    yield history.push("/admin/login");
  }
}

// function* onUpdateProfile({ payload: { data, setSubmitting, id, toggle } }) {
//   try {
//     const url = `/applicant-panel/profile/${id}`;
//     const response = yield call(patch, url, data);
//     // console.log(`response`, response);
//     if (response) {
//       toaster("success", "Update profile successful");
//       setSubmitting && setSubmitting(false);
//       toggle && toggle(true);
//     }
//   } catch (err) {
//     const message = err?.response?.data?.message || "update profile Failed";
//     toaster("error", message);
//     yield put(loginFailure({ message }));
//     setSubmitting && setSubmitting(false);
//   }
// }

function* onUpdateApplicantPassword({
  payload: { data, setSubmitting, toggle },
}) {
  try {
    const url = `/applicant-auth/update-my-password`;
    const response = yield call(patch, url, data);
    // console.log(`response`, response);
    if (response) {
      toaster("success", "Update password successful");
      setSubmitting && setSubmitting(false);
      toggle && toggle(true);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "update password Failed";
    toaster("error", message);
    yield put(loginFailure({ message }));
    setSubmitting && setSubmitting(false);
  }
}

function* onUpdateEmployeePassword({
  payload: { data, setSubmitting, toggle },
}) {
  try {
    const url = `/employee-auth/update-my-password`;
    const response = yield call(patch, url, data);
    // console.log(`response`, response);
    if (response) {
      toaster("success", "Update password successful");
      setSubmitting && setSubmitting(false);
      toggle && toggle(true);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "update password Failed";
    toaster("error", message);
    yield put(loginFailure({ message }));
    setSubmitting && setSubmitting(false);
  }
}

function* onMentorForgotPassword({
  payload: {
    data,
    options: { toggle, resetForm, setSubmitting },
  },
}) {
  try {
    const url = `/employee-auth/forgot-password`;
    const response = yield call(post, url, data);

    if (response) {
      // toaster("success", `Token send your email, please check your email`);
      toggle(true);
      yield setSubmitting(false);
      yield resetForm();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Token send failed, please try again`;
    toaster("error", message);
    toggle(false);
    yield setSubmitting(false);
  }
}

function* onMentorResetPassword({
  payload: {
    data,
    options: { token, history, setSubmitting, resetForm },
  },
}) {
  try {
    const url = `/employee-auth/reset-password/${token}`;
    const response = yield call(patch, url, data);

    if (response) {
      toaster("success", `Change password successfully`);

      yield put(
        login({
          history,
          data: {
            userName: response?.user?.email,
            password: data?.password,
          },
          url: "/employee-auth/login",
          redirect: "/mentor/dashboard",
        })
      );
      yield setSubmitting(false);
      yield resetForm();
    }
  } catch (error) {
    yield setSubmitting(false);
    const message =
      error?.response?.data?.message ||
      `Change password failed, please try again`;
    toaster("error", message);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* loginSaga() {
  yield takeEvery(login.type, onLoginUser);
  yield takeEvery(registerApplicant.type, onRegister);
  // yield takeEvery(updateProfile.type, onUpdateProfile);
  yield takeEvery(updateApplicantPassword.type, onUpdateApplicantPassword);
  yield takeEvery(updateEmployeePassword.type, onUpdateEmployeePassword);
  yield takeEvery(logOutUser.type, onLogOutUser);
  yield takeEvery(mentorForgetPassword.type, onMentorForgotPassword);
  yield takeEvery(mentorResetPassword.type, onMentorResetPassword);
}
