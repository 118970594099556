import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  employeeNotification: {},
  adding: false,
};

const employeeNotificationSlice = createSlice({
  name: "employeeNotification",
  initialState,
  reducers: {
    getEmployeeNotification: (state, action) => {
      state.loading = true;
    },
    getEmployeeNotificationSuccess: (state, action) => {
      state.loading = false;
      state.employeeNotification = action.payload;
    },
    getEmployeeNotificationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const {
  getEmployeeNotification,
  getEmployeeNotificationSuccess,
  getEmployeeNotificationFailure,
} = employeeNotificationSlice.actions;

export default employeeNotificationSlice.reducer;
