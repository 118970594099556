import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  adminNotification: {},
  adding: false,
  rerender: false,
};

const adminNotificationSlice = createSlice({
  name: "adminNotification",
  initialState,
  reducers: {
    getAdminNotification: (state, action) => {
      state.loading = true;
    },
    getAdminNotificationSuccess: (state, action) => {
      state.loading = false;
      state.adminNotification = action.payload;
    },
    getAdminNotificationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    updateAdminNotification: (state, action) => {
      state.loading = true;
    },
    updateAdminNotificationSuccess: (state, action) => {
      state.loading = false;
      state.adminNotification = action.payload;
      state.rerender = !state.rerender;
    },
    updateAdminNotificationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const {
  getAdminNotification,
  getAdminNotificationSuccess,
  getAdminNotificationFailure,
  updateAdminNotification,
  updateAdminNotificationSuccess,
  updateAdminNotificationFailure,
} = adminNotificationSlice.actions;

export default adminNotificationSlice.reducer;
