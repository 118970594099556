import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  taskProgressLoading: false,
  taskProgress: {},
  mentorServiceCoveredLoading: false,
  mentorServiceCovered: {},
  serviceHistoryLoading: false,
  serviceHistory: {},
  servicesStatisticsLoading: false,
  servicesStatistics: {},
  error: "",
};

const mentorDashboardSlice = createSlice({
  name: "mentorDashboard",
  initialState,
  reducers: {
    getTaskProgress: (state, action) => {
      state.taskProgressLoading = true;
    },
    getTaskProgressSuccess: (state, action) => {
      state.taskProgressLoading = false;
      state.taskProgress = action.payload;
    },
    getTaskProgressFail: (state, action) => {
      state.taskProgressLoading = false;
      state.error = action.payload?.message;
    },
    getMentorServiceCovered: (state, action) => {
      state.mentorServiceCoveredLoading = true;
    },
    getMentorServiceCoveredSuccess: (state, action) => {
      state.mentorServiceCoveredLoading = false;
      state.mentorServiceCovered = action.payload;
    },
    getMentorServiceCoveredFail: (state, action) => {
      state.mentorServiceCoveredLoading = false;
      state.error = action.payload?.message;
    },
    getServiceHistory: (state, action) => {
      state.serviceHistoryLoading = true;
    },
    getServiceHistorySuccess: (state, action) => {
      state.serviceHistoryLoading = false;
      state.serviceHistory = action.payload;
    },
    getServiceHistoryFail: (state, action) => {
      state.serviceHistoryLoading = false;
      state.error = action.payload?.message;
    },
    getServicesStatistics: (state, action) => {
      state.servicesStatisticsLoading = true;
    },
    getServicesStatisticsSuccess: (state, action) => {
      state.servicesStatisticsLoading = false;
      state.servicesStatistics = action.payload;
    },
    getServicesStatisticsFail: (state, action) => {
      state.servicesStatisticsLoading = false;
      state.error = action.payload?.message;
    },
  },
});

export const {
  getTaskProgress,
  getTaskProgressSuccess,
  getTaskProgressFail,
  getMentorServiceCovered,
  getMentorServiceCoveredSuccess,
  getMentorServiceCoveredFail,
  getServicesStatistics,
  getServicesStatisticsSuccess,
  getServicesStatisticsFail,
  getServiceHistory,
  getServiceHistorySuccess,
  getServiceHistoryFail,
  getTopBannerFail,
} = mentorDashboardSlice.actions;

export default mentorDashboardSlice.reducer;
