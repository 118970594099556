import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addTestimonial,
  deleteTestimonial,
  deleteTestimonialFailure,
  deleteTestimonialSuccess,
  getTestimonial,
  getTestimonialFailure,
  getTestimonialSuccess,
} from "./TestimonialSlice";

// get all testimonial
function* onGetTestimonial({ payload: { limit = 0, page = 0, status = "" } }) {
  try {
    const url = `/testimonial/data/?limit=${limit}&pageNo=${page}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTestimonialSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get testimonial failed";
    yield put(getTestimonialFailure({ message }));
  }
}

// add or update a testimonial
function* onAddTestimonial({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/testimonial/${id}` : `/testimonial`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Testimonial ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Testimonial ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// delete a testimonial
function* onDeleteTestimonial({ payload: { id, toggle } }) {
  try {
    const url = `/testimonial/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteTestimonialSuccess(response));
      toaster("success", "Testimonial deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Testimonial deleted failed";
    toaster("error", message);
    yield put(deleteTestimonialFailure({ message }));
  }
}

export function* testimonialSaga() {
  yield takeEvery(getTestimonial.type, onGetTestimonial);
  yield takeEvery(addTestimonial.type, onAddTestimonial);
  yield takeEvery(deleteTestimonial.type, onDeleteTestimonial);
}
