import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getApplicantNotification,
  getApplicantNotificationFailure,
  getApplicantNotificationSuccess,
} from "./ApplicationNotificationSlice";

// get all application notification
function* onGetApplicationNotification({
  payload: { limit = 0, page = 1, type = "" },
}) {
  try {
    const url = `/applicant-panel/profile/get-applicant-notification?type=${type}&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getApplicantNotificationSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get notification failed";
    yield put(getApplicantNotificationFailure({ message }));
  }
}

export function* applicationNotificationSaga() {
  yield takeEvery(getApplicantNotification.type, onGetApplicationNotification);
}
