import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import { getUserActiveStatus } from "../../ApplicantPanel/PurchasesService/PurchasesServiceSlice";
import {
  addTaskList,
  getTaskDetails,
  getTaskDetailsFailure,
  getTaskDetailsSuccess,
  getTaskListFailure,
  getTaskListSuccess,
  getTaskLists,
} from "./TaskListSlice";

function* onGetTaskLists({
  payload: { page = 0, limit = 0, serviceId = "", status = "" },
}) {
  try {
    const url = `/employee-panel/sales/get-service-task-list?pageNo=${page}&limit=${limit}&serviceId=${serviceId}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTaskListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get task list failed";
    yield put(getTaskListFailure({ message }));
  }
}

function* onGetTaskListDetails({ payload: { id, isStatus } }) {
  try {
    const url = `/employee-panel/sales/get-service-task-details/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTaskDetailsSuccess(response));
    }
    if (isStatus) {
      yield put(
        getUserActiveStatus({
          userId: response?.service?.applicantId?._id,
          type: "Applicant",
        })
      );
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get task details failed";
    yield put(getTaskDetailsFailure({ message }));
  }
}

function* onUpdateMeetingComplete({
  payload: {
    data,
    options: { setSubmitting, toggle, id },
  },
}) {
  try {
    const url = `/employee-panel/sales/complete-task/${id}`;
    const response = yield call(patch, url, data);

    if (response) {
      // toaster("success", `Meeting complete successfully`);
      yield setSubmitting(false);
      yield toggle(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Meeting complete Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

export function* taskListSaga() {
  yield takeEvery(getTaskLists.type, onGetTaskLists);
  yield takeEvery(getTaskDetails.type, onGetTaskListDetails);
  yield takeEvery(addTaskList.type, onUpdateMeetingComplete);
}
