import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  request: false,
  limit: 10,
  sort: {
    name: "DSC",
    value: "dsc",
  },
  selectedItem: {},
  role: "",
  open: false,
  type: "",
  reRender: false,
  uploading: false,
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    storeFilter: (state, { payload: { name, data } }) => {
      state[name] = data;
      state.reRender = !state.reRender;
    },
    handleMultiClear: (state, { payload: { fields = [] } }) => {
      fields.forEach((fl) => {
        state[fl] = {};
      });
    },
    handleModal: (state, { payload }) => {
      state.open = payload.open;
      state.selectedItem = payload?.data || {};
      state.type = payload.type || "";
    },
    clearFilter(state) {
      state.request = false;
      state.limit = 10;
      state.sort = {
        name: "DSC",
        value: "dsc",
      };
      state.selectedAgency = {};
      state.selectedOrganization = {};
      state.selectedBrand = {};
      state.selectedActivation = {};
      state.selectedItem = {};
      state.selectedUser = {};
      state.selectedLineManager = {};
      state.selectedPoint = {};
      state.role = "";
      state.type = "";
      state.open = false;
      state.selectedRegion = {};
      state.selectedArea = {};
      state.selectedTerritory = {};
      state.viewFull = false;
      state.selectedMonth = "";
    },
  },
});

export const { storeFilter, clearFilter, handleModal, handleMultiClear } =
  filterSlice.actions;

export default filterSlice.reducer;
