import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getActiveUsers,
  getActiveUsersFail,
  getActiveUsersSuccess,
  getPurchasesSummary,
  getPurchasesSummaryFail,
  getPurchasesSummarySuccess,
  getRevenue,
  getRevenueFail,
  getRevenueSuccess,
  getTopBar,
  getTopBarFail,
  getTopBarSuccess,
  getTopMentor,
  getTopMentorFail,
  getTopMentorSuccess,
  getTrendingServices,
  getTrendingServicesFail,
  getTrendingServicesSuccess,
} from "./adminDashboardSlice";

function* onGetTopBar({ payload: { data } }) {
  try {
    const url = `/dashboard/top-banner?currentMonthStartDate=${data?.currentMonthStartDate}&currentMonthEndDate=${data?.currentMonthEndDate}&previousMonthStartDate=${data?.previousMonthStartDate}&previousMonthEndtDate=${data?.previousMonthEndtDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTopBarSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get topbar data failed!";
    yield put(getTopBarFail({ message }));
  }
}

function* onGetPurchases({ payload: { formDate, toDate, type = "" } }) {
  try {
    const url = `/dashboard/sales-summary?type=${type}&fromDate=${formDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPurchasesSummarySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get purchase-summary failed!";
    yield put(getPurchasesSummaryFail({ message }));
  }
}

function* onGetTrendingServices({ payload: { formDate, toDate, type = "" } }) {
  try {
    const url = `/dashboard/trending-service?type=${type}&fromDate=${formDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTrendingServicesSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get trending services failed!";
    yield put(getTrendingServicesFail({ message }));
  }
}

function* onGetActiveUsers({ payload: { data } }) {
  try {
    const url = "/dashboard/xyz";
    const response = yield call(get, url);
    if (response) {
      yield put(getActiveUsersSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get active Users failed!";
    yield put(getActiveUsersFail({ message }));
  }
}

function* onGetRevenue({ payload: { year } }) {
  try {
    const url = `/dashboard/revenue-refund?year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getRevenueSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get revenue failed!";
    yield put(getRevenueFail({ message }));
  }
}

function* onGetTopMentor() {
  try {
    const url = "/dashboard/top-mentor";
    const response = yield call(get, url);
    // console.log("reponse", response);
    if (response) {
      yield put(getTopMentorSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get top mentor failed!";
    yield put(getTopMentorFail({ message }));
  }
}

export function* adminDashboardSaga() {
  yield takeEvery(getTopBar.type, onGetTopBar);
  yield takeEvery(getPurchasesSummary.type, onGetPurchases);
  yield takeEvery(getTrendingServices.type, onGetTrendingServices);
  yield takeEvery(getActiveUsers.type, onGetActiveUsers);
  yield takeEvery(getRevenue.type, onGetRevenue);
  yield takeEvery(getTopMentor.type, onGetTopMentor);
}
