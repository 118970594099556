import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getEmployeeNotification,
  getEmployeeNotificationFailure,
  getEmployeeNotificationSuccess,
} from "./EmployeeNotificationSlice";

// get all employee notification
function* onGetEmployeeNotification({
  payload: { limit = 0, page = 1, type = "" },
}) {
  try {
    const url = `/employee-panel/dashboard/get-mentor-notification?type=${type}&limit${limit}&pageNo${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getEmployeeNotificationSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get notification failed";
    yield put(getEmployeeNotificationFailure({ message }));
  }
}

export function* employeeNotificationSaga() {
  yield takeEvery(getEmployeeNotification.type, onGetEmployeeNotification);
}
