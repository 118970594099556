import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  meetingSchedule: {},
};

const meetingScheduleMentorSlice = createSlice({
  name: "meetingScheduleMentor",
  initialState: initialState,
  reducers: {
    getMeetingSchedules: (state, action) => {
      state.loading = true;
    },
    getMeetingScheduleSuccess: (state, action) => {
      state.meetingSchedule = action.payload;
      state.loading = false;
    },
    getMeetingScheduleFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
  },
});

export const {
  getMeetingSchedules,
  getMeetingScheduleSuccess,
  getMeetingScheduleFailure,
} = meetingScheduleMentorSlice.actions;

export default meetingScheduleMentorSlice.reducer;
