import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addTimeSlot,
  deleteTimeSlot,
  deleteTimeSlotFailure,
  deleteTimeSlotSuccess,
  getTimeSlot,
  getTimeSlotFailure,
  getTimeSlotSuccess,
} from "./TimeSlotSlice";

// get all time-slot
function* onGetTimeSlot({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/time-slot/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTimeSlotSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get time slot failed";
    yield put(getTimeSlotFailure({ message }));
  }
}

// add a career time-slot
function* onAddTimeSlot({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/time-slot/${id}` : `/time-slot`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Time slot ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Time Slot ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// delete a time-slot
function* onDeleteTimeSlot({ payload: { id, toggle } }) {
  try {
    const url = `/time-slot/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteTimeSlotSuccess(response));
      toaster("success", "Time slot deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Time slot deleted failed";
    toaster("error", message);
    yield put(deleteTimeSlotFailure({ message }));
  }
}

export function* timeSlotSaga() {
  yield takeEvery(getTimeSlot.type, onGetTimeSlot);
  yield takeEvery(addTimeSlot.type, onAddTimeSlot);
  yield takeEvery(deleteTimeSlot.type, onDeleteTimeSlot);
}
