import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addApplicantData,
  getApplicantList,
  getApplicantListFailure,
  getApplicantListSuccess,
  getSingleApplicant,
  getSingleApplicantFailure,
  getSingleApplicantSuccess
} from "./ApplicantSlice";

function* onGetApplicant({ payload: { page = 1, limit } }) {
  try {
    const url = `/dashboard/applicant-list?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      // console.log(` response:`, response);
      yield put(getApplicantListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Applicants Failed";
    yield put(getApplicantListFailure({ message }));
  }
}

function* onAddApplicant({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/applicant/${id}` : `/applicant`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Applicant ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(
        getApplicantList({ page: 1, limit: 10, role: "", organization: "" })
      );
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Applicants Failed`;
    toaster("error", message);
    yield setSubmitting(false);
    yield resetForm();
  }
}

function* onGetSingleApplicant({ payload: { id } }) {
  try {
    const url = `/dashboard/applicant-detsils/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleApplicantSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single applicant failed";
    toaster("error", message);
    yield put(getSingleApplicantFailure({ message }));
  }
}

export function* applicantSaga() {
  yield takeEvery(getApplicantList.type, onGetApplicant);
  yield takeEvery(addApplicantData.type, onAddApplicant);
  yield takeEvery(getSingleApplicant.type, onGetSingleApplicant);
}
