import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  careerCategories: {},
  adding: false,
};

const careerCategoriesSlice = createSlice({
  name: "careerCategories",
  initialState,
  reducers: {
    getCareerCategories: (state, action) => {
      state.loading = true;
    },
    getCareerCategoriesSuccess: (state, action) => {
      state.loading = false;
      state.careerCategories = action.payload;
    },
    getCareerCategoriesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addCareerCategories: (state, action) => {},
    deleteCareerCategories: (state, action) => {
      state.adding = true;
    },
    deleteCareerCategoriesSuccess: (state, action) => {
      state.adding = false;
    },
    deleteCareerCategoriesFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getCareerCategories,
  getCareerCategoriesSuccess,
  getCareerCategoriesFailure,
  addCareerCategories,
  deleteCareerCategories,
  deleteCareerCategoriesFailure,
  deleteCareerCategoriesSuccess,
} = careerCategoriesSlice.actions;

export default careerCategoriesSlice.reducer;
