import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getMentorServiceCovered,
  getMentorServiceCoveredFail,
  getMentorServiceCoveredSuccess,
  getServiceHistory,
  getServiceHistoryFail,
  getServiceHistorySuccess,
  getServicesStatistics,
  getServicesStatisticsFail,
  getServicesStatisticsSuccess,
  getTaskProgress,
  getTaskProgressFail,
  getTaskProgressSuccess,
} from "./mentorDashboardSlice";

function* onGetTaskProgress({ payload: { formDate, toDate, type = "" } }) {
  try {
    const url = `/employee-panel/dashboard/task-progress?type=${type}&fromDate=${formDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTaskProgressSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get task progress failed!";
    yield put(getTaskProgressFail({ message }));
  }
}

function* onGetMentorServiceCovered({
  payload: { formDate, toDate, type = "" },
}) {
  try {
    const url = `/employee-panel/dashboard/mentor-service-covered?type=${type}&fromDate=${formDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getMentorServiceCoveredSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get mentor service covered failed!";
    yield put(getMentorServiceCoveredFail({ message }));
  }
}

function* onGetServiceHistory({ payload: { formDate, toDate, type = "" } }) {
  try {
    const url = `/employee-panel/dashboard/service-history?type=${type}&fromDate=${formDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getServiceHistorySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get service history failed!";
    yield put(getServiceHistoryFail({ message }));
  }
}

function* onGetServicesStatistics({ payload: { year } }) {
  try {
    const url = `/employee-panel/dashboard/service-statistics?year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getServicesStatisticsSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get services statistics failed!";
    yield put(getServicesStatisticsFail({ message }));
  }
}

export function* mentorDashboardSaga() {
  yield takeEvery(getTaskProgress.type, onGetTaskProgress);
  yield takeEvery(getMentorServiceCovered.type, onGetMentorServiceCovered);
  yield takeEvery(getServiceHistory.type, onGetServiceHistory);
  yield takeEvery(getServicesStatistics.type, onGetServicesStatistics);
}
