import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addMentor,
  getReportService,
  getReportServiceFailure,
  getReportServiceSuccess,
  getSalesDetails,
  getSalesDetailsFailure,
  getSalesDetailsSuccess,
  getSalesList,
  getSalesListFailure,
  getSalesListSuccess,
  getSalesReport,
  getSalesReportFailure,
  getSalesReportSuccess,
  getServiceSalesSummary,
  getServiceSalesSummaryFailure,
  getServiceSalesSummarySuccess,
} from "./SalesDetailsSlice";

// get all salesList
function* onGetServiceSalesSummary() {
  try {
    const url = `/sales/get-service-sales-summary`;
    const response = yield call(get, url);
    if (response) {
      yield put(getServiceSalesSummarySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get service sales data failed";
    yield put(getServiceSalesSummaryFailure({ message }));
  }
}

// get all salesList
function* onGetSalesList({
  payload: { limit = 0, page = 0, serviceId = "", status = "" },
}) {
  try {
    const url = `/sales/get-service-sales-list-by-Id/?limit=${limit}&pageNo=${page}&serviceId=${serviceId}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSalesListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get sales data failed";
    yield put(getSalesListFailure({ message }));
  }
}

// get all sales Details
function* onGetSalesDetails({ payload: { salesId = "" } }) {
  try {
    const url = `/sales/service-sales-details/${salesId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSalesDetailsSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get sales details failed";
    yield put(getSalesDetailsFailure({ message }));
  }
}

// get all sales Report
function* onGetSalesReport({
  payload: {
    mentorId = "",
    serviceId = "",
    status = "",
    fromDate = "",
    toDate = "",
  },
}) {
  try {
    const url = `/sales/service-sales-report/?mentorId=${mentorId}&serviceId=${serviceId}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSalesReportSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get sales report failed";
    yield put(getSalesReportFailure({ message }));
  }
}

// get add mentor
function* onAddMentor({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id, socket, data: mainData },
  },
}) {
  try {
    const url = `/sales/mentor-assign/${id}`;
    const response = yield call(patch, url, data);
    // console.log(`response:`, response);
    if (response) {
      toaster("success", `Mentor added successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
      socket?.emit(`SendNotification`, {
        applicant: mainData?.applicantId?._id,
        mentor: data?.mentorId,
        // admin: false,
      });
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Mentor added failed";
    yield put(getSalesDetailsFailure({ message }));
  }
}

// get all report service
function* onGetReportService({ payload: { id } }) {
  try {
    const url = `/sales/service-sales-details/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getReportServiceSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get service report data failed";
    yield put(getReportServiceFailure({ message }));
  }
}

export function* salesListSaga() {
  yield takeEvery(getServiceSalesSummary.type, onGetServiceSalesSummary);
  yield takeEvery(getSalesList.type, onGetSalesList);
  yield takeEvery(getSalesReport.type, onGetSalesReport);
  yield takeEvery(getSalesDetails.type, onGetSalesDetails);
  yield takeEvery(getReportService.type, onGetReportService);
  yield takeEvery(addMentor.type, onAddMentor);
}
