import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch } from "../../../helpers/api_helper";
import {
  getAdminNotification,
  getAdminNotificationFailure,
  getAdminNotificationSuccess,
  updateAdminNotification,
  updateAdminNotificationFailure,
  updateAdminNotificationSuccess,
} from "./AdminNotificationSlice";

// get all admin notification
function* onGetAdminNotification({
  payload: { limit = 0, page = 1, type = "" },
}) {
  try {
    const url = `/dashboard/get-admin-notification?type=${type}&limit${limit}&pageNo${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAdminNotificationSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get notification failed";
    yield put(getAdminNotificationFailure({ message }));
  }
}

const notificationHelper = (data, type) => {
  switch (data?.type) {
    case "serviceSale":
      return `/${type}/services-sales/${data.url}`;
    case "serviceComplete":
      return `/${type}/${type === "user" ? "purchases" : "services-sales"}/${
        data.url
      }`;
    case "fileSend":
      return `/${type}/purchases/${data.url}`;
    case "AssignMentor":
      return `/${type}/${type === "user" ? "purchases" : "tasklist"}/${
        data.url
      }`;
    case "meetingRequest":
      return `/${type}/tasklist/${data.url}`;
    case "mentorRating":
      return `/${type}/tasklist/${data.url}`;
    // case "serviceComplete":
    //   return `/${type}/services-sales/${data.url}`;
    default:
      return "/";
  }
};

function* onUpdateAdminNotification({
  payload: { id, history, notificationData, type },
}) {
  try {
    const url = `/data-dump/read-notification/${id}`;
    const response = yield call(patch, url);
    if (response) {
      yield put(updateAdminNotificationSuccess(response));
      const url = notificationHelper(notificationData, type);
      history?.push(url);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get notification failed";
    yield put(updateAdminNotificationFailure({ message }));
  }
}

export function* adminNotificationSaga() {
  yield takeEvery(getAdminNotification.type, onGetAdminNotification);
  yield takeEvery(updateAdminNotification.type, onUpdateAdminNotification);
}
