import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";

import {
  addBannerSection,
  deleteBannerSection,
  deleteBannerSectionFailure,
  deleteBannerSectionSuccess,
  getBannerSection,
  getBannerSectionFailure,
  getBannerSectionSuccess,
} from "./BannerSectionSlice";

// get all bannerSection
function* onGetBannerSection({ payload: { limit = 0, page = 0 } }) {
  try {
    const url = `/slide/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getBannerSectionSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get banner section failed";
    yield put(getBannerSectionFailure({ message }));
  }
}

// add or update a slide
function* onAddBannerSection({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/slide/${id}` : `/slide`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Banner Section ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Banner Section ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// delete a bannerSection
function* onDeleteBannerSection({ payload: { id, toggle } }) {
  try {
    const url = `/slide/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteBannerSectionSuccess(response));
      toaster("success", "Banner Section deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Banner Section deleted failed";
    toaster("error", message);
    yield put(deleteBannerSectionFailure({ message }));
  }
}

export function* bannerSectionSaga() {
  yield takeEvery(getBannerSection.type, onGetBannerSection);
  yield takeEvery(addBannerSection.type, onAddBannerSection);
  yield takeEvery(deleteBannerSection.type, onDeleteBannerSection);
}
