import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  timeSlot: {},
  adding: false,
};

const timeSlotSlice = createSlice({
  name: "timeSlot",
  initialState,
  reducers: {
    getTimeSlot: (state, action) => {
      state.loading = true;
    },
    getTimeSlotSuccess: (state, action) => {
      state.loading = false;
      state.timeSlot = action.payload;
    },
    getTimeSlotFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addTimeSlot: (state, action) => {},
    deleteTimeSlot: (state, action) => {
      state.adding = true;
    },
    deleteTimeSlotSuccess: (state, action) => {
      state.adding = false;
    },
    deleteTimeSlotFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getTimeSlot,
  getTimeSlotSuccess,
  getTimeSlotFailure,
  addTimeSlot,
  deleteTimeSlot,
  deleteTimeSlotFailure,
  deleteTimeSlotSuccess,
} = timeSlotSlice.actions;

export default timeSlotSlice.reducer;
