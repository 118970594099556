import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  checkoutPayment: {},
  adding: false,
};

const checkoutPaymentSlice = createSlice({
  name: "checkoutPayment",
  initialState,
  reducers: {
    getCheckoutPayment: (state, action) => {
      state.loading = true;
    },
    getCheckoutPaymentSuccess: (state, action) => {
      state.loading = false;
      state.checkoutPayment = action.payload;
    },
    getCheckoutPaymentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addCheckoutPayment: (state, action) => {},
    deleteCheckoutPayment: (state, action) => {
      state.adding = true;
    },
    deleteCheckoutPaymentSuccess: (state, action) => {
      state.adding = false;
    },
    deleteCheckoutPaymentFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getCheckoutPayment,
  getCheckoutPaymentSuccess,
  getCheckoutPaymentFailure,
  addCheckoutPayment,
  deleteCheckoutPayment,
  deleteCheckoutPaymentFailure,
  deleteCheckoutPaymentSuccess,
} = checkoutPaymentSlice.actions;

export default checkoutPaymentSlice.reducer;
