import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  applicantNotification: {},
  adding: false,
};

const applicantNotificationSlice = createSlice({
  name: "applicantNotification",
  initialState,
  reducers: {
    getApplicantNotification: (state, action) => {
      state.loading = true;
    },
    getApplicantNotificationSuccess: (state, action) => {
      state.loading = false;
      state.applicantNotification = action.payload;
    },
    getApplicantNotificationFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const {
  getApplicantNotification,
  getApplicantNotificationSuccess,
  getApplicantNotificationFailure,
} = applicantNotificationSlice.actions;

export default applicantNotificationSlice.reducer;
