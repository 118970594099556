import React from "react";
import logoLightSvg from "../../assets/Images/Icon/mainLogo.svg";

const Loading = () => {
  return (
    <React.Fragment>
      <div
        className="d-flex justify-content-center align-items-center "
        style={{ height: "100vh" }}
      >
        <img src={logoLightSvg} alt="" height="50" />
        <i
          className="bx bx-loader bx-spin fs-3"
          style={{ color: "#2ba3c2" }}
        ></i>
        {/* <div>
          <div>
          </div>
          <div className="snippet" data-title=".dot-pulse">
            <div className="stage">
              <div className="dot-pulse"></div>
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Loading;
