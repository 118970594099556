import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addCheckoutPayment,
  deleteCheckoutPayment,
  deleteCheckoutPaymentFailure,
  deleteCheckoutPaymentSuccess,
  getCheckoutPayment,
  getCheckoutPaymentFailure,
  getCheckoutPaymentSuccess,
} from "./CheckoutSlice";

// get all checkoutPayment
function* onGetCheckoutPayment({
  payload: { limit = 0, page = 0, status = "" },
}) {
  try {
    const url = `/checkoutPayment/data/?limit=${limit}&pageNo=${page}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCheckoutPaymentSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get checkoutPayment failed";
    yield put(getCheckoutPaymentFailure({ message }));
  }
}

// add or update a checkoutPayment
function* onAddCheckoutPayment({ payload: { data, setIsLoading } }) {
  try {
    const url = `/applicant-panel/sales/create-sales`;
    const response = yield call(post, url, data);
    if (response) {
      // toaster("success", `CheckoutPayment Added Successfully`);
      window.location.href = response?.link;
      // yield setSubmitting(false);
      // yield resetForm();
      // yield toggle(true);
      // setIsLoading(false);
    }
  } catch (error) {
    setIsLoading(false);
    const message = error?.response?.data?.message || `Checkout Payment Failed`;
    toaster("error", message);
    // yield setSubmitting(false);
  }
}

// delete a checkoutPayment
function* onDeleteCheckoutPayment({ payload: { id, toggle } }) {
  try {
    const url = `/checkoutPayment/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteCheckoutPaymentSuccess(response));
      toaster("success", "CheckoutPayment deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "CheckoutPayment deleted failed";
    toaster("error", message);
    yield put(deleteCheckoutPaymentFailure({ message }));
  }
}

export function* checkoutPaymentSaga() {
  yield takeEvery(getCheckoutPayment.type, onGetCheckoutPayment);
  yield takeEvery(addCheckoutPayment.type, onAddCheckoutPayment);
  yield takeEvery(deleteCheckoutPayment.type, onDeleteCheckoutPayment);
}
