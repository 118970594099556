import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  topBarLoading: true,
  topBar: {},
  purchasesSummaryLoading: true,
  purchasesSummary: {},
  trendingServicesLoading: true,
  trendingServices: {},
  activeUsersLoading: true,
  activeUsers: {},
  revenueLoading: true,
  revenue: {},
  topMentorLoading: true,
  topMentor: {},
  error: "",
};

const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    getTopBar: (state) => {
      state.topBarLoading = true;
    },
    getTopBarSuccess: (state, action) => {
      state.topBarLoading = false;
      state.topBar = action.payload;
    },
    getTopBarFail: (state, action) => {
      state.topBarLoading = false;
      state.error = action.payload?.message;
    },
    getPurchasesSummary: (state) => {
      state.purchasesSummaryLoading = true;
    },
    getPurchasesSummarySuccess: (state, action) => {
      state.purchasesSummaryLoading = false;
      state.purchasesSummary = action.payload;
    },
    getPurchasesSummaryFail: (state, action) => {
      state.purchasesSummaryLoading = false;
      state.error = action.payload?.message;
    },
    getTrendingServices: (state) => {
      state.trendingServicesLoading = true;
    },
    getTrendingServicesSuccess: (state, action) => {
      state.trendingServicesLoading = false;
      state.trendingServices = action.payload;
    },
    getTrendingServicesFail: (state, action) => {
      state.trendingServicesLoading = false;
      state.error = action.payload?.message;
    },
    getActiveUsers: (state) => {
      state.activeUsersLoading = true;
    },
    getActiveUsersSuccess: (state, action) => {
      state.activeUsersLoading = false;
      state.activeUsers = action.payload;
    },
    getActiveUsersFail: (state, action) => {
      state.activeUsersLoading = false;
      state.error = action.payload?.message;
    },
    getRevenue: (state) => {
      state.revenueLoading = true;
    },
    getRevenueSuccess: (state, action) => {
      state.revenueLoading = false;
      state.revenue = action.payload;
    },
    getRevenueFail: (state, action) => {
      state.revenueLoading = false;
      state.error = action.payload?.message;
    },
    getTopMentor: (state) => {
      state.topMentorLoading = true;
    },
    getTopMentorSuccess: (state, action) => {
      state.topMentorLoading = false;
      state.topMentor = action.payload;
    },
    getTopMentorFail: (state, action) => {
      state.topMentorLoading = false;
      state.error = action.payload?.message;
    },
  },
});

export const {
  getTopBar,
  getTopBarSuccess,
  getTopBarFail,
  getPurchasesSummary,
  getPurchasesSummarySuccess,
  getPurchasesSummaryFail,
  getTrendingServices,
  getTrendingServicesSuccess,
  getTrendingServicesFail,
  getActiveUsers,
  getActiveUsersSuccess,
  getActiveUsersFail,
  getRevenue,
  getRevenueSuccess,
  getRevenueFail,
  getTopMentor,
  getTopMentorSuccess,
  getTopMentorFail,
} = adminDashboardSlice.actions;

export default adminDashboardSlice.reducer;
