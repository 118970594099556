import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  cvScoring: {},
  adding: false,
  reRender: false,
};

const cvScoringSlice = createSlice({
  name: "cvScoring",
  initialState,
  reducers: {
    storeCvScoring: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
    reRenderCvScoring: (state) => {
      state.reRender = !state.reRender;
    },
    getCvScoring: (state, action) => {
      state.loading = true;
    },
    getCvScoringSuccess: (state, action) => {
      state.loading = false;
      state.cvScoring = action.payload;
    },
    getCvScoringFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    addCvScoring: (state, action) => {},
  },
});

export const {
  getCvScoring,
  getCvScoringSuccess,
  getCvScoringFailure,
  addCvScoring,
  reRenderCvScoring,
} = cvScoringSlice.actions;

export default cvScoringSlice.reducer;
