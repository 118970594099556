import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  token: "",
  loading: false,
  role: "",
  roles: [],
  success: "",
  error: "",
  user: {},
};
const projectName = process.env.REACT_APP_NAME;

const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    login(state) {
      state.loading = true;
    },
    adminLogin(state) {
      state.loading = true;
    },
    loginSuccess(state, { payload }) {
      state.token = payload.token;
      state.role = payload.role;
      state.roles = payload.roles;
      state.user = payload.user;
      state.loading = false;
    },
    loginFailure(state, { payload }) {
      state.success = "";
      state.error = payload.message;
      state.loading = false;
    },
    registerApplicant(state) {
      // state.loading = true;
    },
    logOutUser(state) {
      state.token = "";
      state.role = "";
      state.roles = [];
      state.user = {};
    },
    updateApplicantPassword(state) {},
    updateEmployeePassword(state) {},
    mentorForgetPassword(state) {},
    mentorResetPassword: (state, action) => {},
  },
});

export const {
  login,
  loginSuccess,
  loginFailure,
  logOutUser,
  registerApplicant,
  updateApplicantPassword,
  updateEmployeePassword,
  mentorForgetPassword,
  mentorResetPassword,
} = loginSlice.actions;

const persistConfig = {
  keyPrefix: `${projectName}-`,
  key: "login",
  storage,
};

export default persistReducer(persistConfig, loginSlice.reducer);
