import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileData: {},
  loading: false,
  error: "",
};

const profileUserSlice = createSlice({
  name: "profileUser",
  initialState: initialState,
  reducers: {
    getProfileUser: (state, action) => {
      state.loading = true;
    },
    getProfileUserSuccess: (state, action) => {
      state.profileData = action.payload;
      state.loading = false;
    },
    getProfileUserFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    updateProfileUser: (state, action) => {},
    updateUserPassword: (state, action) => {},
    userForgotPassword: (state, action) => {},
    userResetPassword: (state, action) => {},
  },
});

export const {
  getProfileUser,
  getProfileUserSuccess,
  getProfileUserFailure,
  updateProfileUser,
  updateUserPassword,
  userForgotPassword,
  userResetPassword,
} = profileUserSlice.actions;

export default profileUserSlice.reducer;
