import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addCareerServices,
  deleteCareerServices,
  deleteCareerServicesFailure,
  deleteCareerServicesSuccess,
  getCareerServices,
  getCareerServicesFailure,
  getCareerServicesSuccess,
  getCareerSingleServices,
  getCareerSingleServicesFailure,
  getCareerSingleServicesSuccess,
} from "./CareerServicesSlice";

// get all career services
function* onGetCareerServices({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/service/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCareerServicesSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get service failed";
    yield put(getCareerServicesFailure({ message }));
  }
}

// add a career service
function* onAddCareerService({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/service/${id}` : `/service`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Service ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Service ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// get a career service
function* onGetSingleCareerService({ payload: { id, toggle } }) {
  try {
    const url = `/service/details/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCareerSingleServicesSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Single service get failed";
    toaster("error", message);
    yield put(getCareerSingleServicesFailure({ message }));
  }
}

// delete a career service
function* onDeleteCareerService({ payload: { id, toggle } }) {
  try {
    const url = `/service/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteCareerServicesSuccess(response));
      toaster("success", "Service deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Service deleted failed";
    toaster("error", message);
    yield put(deleteCareerServicesFailure({ message }));
  }
}

export function* careerServicesSaga() {
  yield takeEvery(getCareerServices.type, onGetCareerServices);
  yield takeEvery(getCareerSingleServices.type, onGetSingleCareerService);
  yield takeEvery(addCareerServices.type, onAddCareerService);
  yield takeEvery(deleteCareerServices.type, onDeleteCareerService);
}
