import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addCareerCategories,
  deleteCareerCategories,
  deleteCareerCategoriesFailure,
  deleteCareerCategoriesSuccess,
  getCareerCategories,
  getCareerCategoriesFailure,
  getCareerCategoriesSuccess,
} from "./CareerCategoriesSlice";

// get all career categories
function* onGetCareerCategories({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/career-advice-category/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCareerCategoriesSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Career advice Category failed";
    yield put(getCareerCategoriesFailure({ message }));
  }
}

// add a career category
function* onAddCareerCategory({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/career-advice-category/${id}`
      : `/career-advice-category`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Career advice Category ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Career advice category ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// delete a career category
function* onDeleteCareerCategory({ payload: { id, toggle } }) {
  try {
    const url = `/career-advice-category/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteCareerCategoriesSuccess(response));
      toaster("success", "Career advice category deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Career advice category deleted failed";
    toaster("error", message);
    yield put(deleteCareerCategoriesFailure({ message }));
  }
}

export function* careerCategoriesSaga() {
  yield takeEvery(getCareerCategories.type, onGetCareerCategories);
  yield takeEvery(addCareerCategories.type, onAddCareerCategory);
  yield takeEvery(deleteCareerCategories.type, onDeleteCareerCategory);
}
