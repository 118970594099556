import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addMentorList,
  deleteMentorList,
  deleteMentorListFailure,
  deleteMentorListSuccess,
  getMentorList,
  getMentorListFailure,
  getMentorListSuccess,
} from "./MentorListSlice";

// get all mentorList
function* onGetMentorList({ payload: { limit = 0, page = 0, status = "" } }) {
  try {
    const url = `/employee/data/?limit=${limit}&pageNo=${page}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getMentorListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get mentor list failed";
    yield put(getMentorListFailure({ message }));
  }
}

// add or update a mentorList
function* onAddMentorList({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/employee/${id}` : `/employee`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Mentor List ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Mentor List ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// delete a mentorList
function* onDeleteMentorList({ payload: { id, toggle } }) {
  try {
    const url = `/employee/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteMentorListSuccess(response));
      toaster("success", "Mentor List deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Mentor List deleted failed";
    toaster("error", message);
    yield put(deleteMentorListFailure({ message }));
  }
}

export function* mentorListSaga() {
  yield takeEvery(getMentorList.type, onGetMentorList);
  yield takeEvery(addMentorList.type, onAddMentorList);
  yield takeEvery(deleteMentorList.type, onDeleteMentorList);
}
