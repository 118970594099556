import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getFeedbackByDepartment,
  getFeedbackByDepartmentFail,
  getFeedbackByDepartmentSuccess,
  getFeedbackByDesignation,
  getFeedbackByDesignationFail,
  getFeedbackByDesignationSuccess,
  getTopAchiever,
  getTopAchieverFail,
  getTopAchieverSuccess,
  getTopFeedbackSubmit,
  getTopFeedbackSubmitFail,
  getTopFeedbackSubmitSuccess,
} from "./islamGroupDashboardSlice";

function* onGetTopFeedbackSubmit() {
  try {
    const url = `/dashboard/total-feedback-submit-or-not`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTopFeedbackSubmitSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get feedback submitted data failed!";
    yield put(getTopFeedbackSubmitFail({ message }));
  }
}

function* onGetFeedbackByDepartment() {
  try {
    const url = `/dashboard/number-of-feedback-by-department`;
    const response = yield call(get, url);
    if (response) {
      yield put(getFeedbackByDepartmentSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get feedback department data failed!";
    yield put(getFeedbackByDepartmentFail({ message }));
  }
}

function* onGetFeedbackByDesignation() {
  try {
    const url = `/users/get-sum-of-form-designation`;
    const response = yield call(get, url);
    if (response) {
      yield put(getFeedbackByDesignationSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get feedback designation data failed!";
    yield put(getFeedbackByDesignationFail({ message }));
  }
}

function* onGetTopAchiever({ payload: { department = "", designation = "" } }) {
  try {
    const url = `/dashboard/top-achiever?department=${department}&designation=${designation}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTopAchieverSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get top achiever data failed!";
    yield put(getTopAchieverFail({ message }));
  }
}

export function* islamGroupDashboardSaga() {
  yield takeEvery(getTopFeedbackSubmit.type, onGetTopFeedbackSubmit);
  yield takeEvery(getFeedbackByDepartment.type, onGetFeedbackByDepartment);
  yield takeEvery(getFeedbackByDesignation.type, onGetFeedbackByDesignation);
  yield takeEvery(getTopAchiever.type, onGetTopAchiever);
}
