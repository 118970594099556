import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// import App from './App';
import "./assets/reset.scss";
import "./assets/scss/theme.scss";
import Loading from "./Components/Atoms/Loading";
// import SocketProvider from './Context/SocketProvider';
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "react-calendar/dist/Calendar.css";
import "react-circular-progressbar/dist/styles.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import SocketProvider from "./Context/SocketProvider";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import store from "./Store/store";
import "./Styles/style.css";
const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SocketProvider>
        <Provider store={store}>
          <PersistGate persistor={store.__persistor} loading={false}>
            {/* <SocketProvider> */}
            <Suspense fallback={<Loading />}>
              <App />
            </Suspense>
            {/* </SocketProvider> */}
          </PersistGate>
        </Provider>
      </SocketProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
