import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addPassageData,
  deletePassage,
  deletePassageFailure,
  deletePassageSuccess,
  getPassageList,
  getPassageListFailure,
  getPassageListSuccess,
  getSinglePassage,
  getSinglePassageFailure,
  getSinglePassageSuccess,
} from "./PassageSlice";

function* onGetPassage({
  payload: { page = 1, limit = 10, serviceId = "", level = "", type = "" },
}) {
  try {
    let api = "";
    switch (type) {
      case "listening":
        api = "/listening/get-listenings";
        break;
      case "reading":
        api = "/reading/get-reading";
        break;
      default:
        break;
    }
    const url = `/admin${api}/${serviceId}/${level}?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPassageListSuccess(response?.getData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get passage failed";
    yield put(getPassageListFailure({ message }));
  }
}

function* onAddPassage({
  payload: {
    data,
    options: {
      setSubmitting,
      resetForm,
      id = "",
      type = "",
      history,
      serviceId = "",
    },
  },
}) {
  try {
    let api = "";
    switch (type) {
      case "listening":
        api = id
          ? `/admin/listening/update-listening/${id}`
          : "/admin/listening/audio-data-create";
        break;
      case "reading":
        api = id
          ? `/admin/reading/update-reading/${id}`
          : "/admin/reading/reading-passage-data-create";
        break;
      default:
        break;
    }
    const url = api;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Set ${id ? "Updated" : "Added"} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      history.push(`/admin/services/${serviceId}/${type}`);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? "Update" : "Add"} Set Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSinglePassage({ payload: { id, type } }) {
  try {
    const url = `/admin/${type}/${id}/${
      type === "listening" ? "get-single-file" : "get-single-passage"
    }`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSinglePassageSuccess(response?.getData));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single passage failed";
    toaster("error", message);
    yield put(getSinglePassageFailure({ message }));
  }
}

function* onDeletePassage({ payload: { id, successCallBack } }) {
  try {
    const url = `/passage/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deletePassageSuccess(response));
      toaster("success", "Set Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete passage failed";
    toaster("error", message);
    yield put(deletePassageFailure({ message }));
  }
}

export function* passageSaga() {
  yield takeEvery(getPassageList.type, onGetPassage);
  yield takeEvery(addPassageData.type, onAddPassage);
  yield takeEvery(getSinglePassage.type, onGetSinglePassage);
  yield takeEvery(deletePassage.type, onDeletePassage);
}
