import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addPurchasesRating,
  addPurchasesService,
  getPurchasesService,
  getPurchasesServiceDetails,
  getPurchasesServiceDetailsFailure,
  getPurchasesServiceDetailsSuccess,
  getPurchasesServiceFailure,
  getPurchasesServiceSuccess,
  getUserActiveStatus,
  getUserActiveStatusFailure,
  getUserActiveStatusSuccess,
  loadMessage,
  loadMessageFailure,
  loadMessageSuccess,
} from "./PurchasesServiceSlice";

// get all purchases services
function* onGetPurchasesService() {
  try {
    const url = `/applicant-panel/sales/service-list`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPurchasesServiceSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get service failed";
    yield put(getPurchasesServiceFailure({ message }));
  }
}

// get all purchases services details
function* onGetPurchasesServiceDetails({ payload: { id, isStatus } }) {
  try {
    const url = `/applicant-panel/sales/service-data-by-id/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPurchasesServiceDetailsSuccess(response));
    }
    if (isStatus) {
      yield put(
        getUserActiveStatus({
          userId: response?.service?.mentorId?._id,
          type: "Mentor",
        })
      );
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get service details failed";
    yield put(getPurchasesServiceDetailsFailure({ message }));
  }
}

// add a purchases service
function* onAddPurchasesService({
  payload: {
    data,
    options: { setSubmitting, toggle, id },
  },
}) {
  try {
    const url = `/applicant-panel/sales/choose-meeting-schedule/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `Meeting added successfully`);
      yield setSubmitting(false);
      yield toggle(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Meeting added failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// add a purchases service
function* onRatingPurchasesRating({
  payload: {
    data,
    options: { setSubmitting, toggle, id },
  },
}) {
  try {
    const url = `/applicant-panel/sales/review-submit-to-mentor/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `Rating added successfully`);
      yield setSubmitting(false);
      yield toggle(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Rating added failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// get all user message
function* onLoadMessage({ payload: { taskId } }) {
  try {
    const url = `/data-dump/get-live-chat-data?id=${taskId}&limit=0&pageNo=0`;
    const response = yield call(get, url);
    if (response) {
      yield put(loadMessageSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get messages failed";
    yield put(loadMessageFailure({ message }));
  }
}

// get user status
function* onGetUserActiveStatus({ payload: { userId, type } }) {
  try {
    const url = `/data-dump/check-user-online-status?userId=${userId}&type=${type}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getUserActiveStatusSuccess(response?.userData));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get messages failed";
    yield put(getUserActiveStatusFailure({ message }));
  }
}

export function* purchasesServicesSaga() {
  yield takeEvery(getPurchasesService.type, onGetPurchasesService);
  yield takeEvery(
    getPurchasesServiceDetails.type,
    onGetPurchasesServiceDetails
  );
  yield takeEvery(addPurchasesService.type, onAddPurchasesService);
  yield takeEvery(addPurchasesRating.type, onRatingPurchasesRating);
  yield takeEvery(loadMessage.type, onLoadMessage);
  yield takeEvery(getUserActiveStatus.type, onGetUserActiveStatus);
}
