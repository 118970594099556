import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addMeetingSchedule,
  deleteMeetingSchedule,
  deleteMeetingScheduleFailure,
  deleteMeetingScheduleSuccess,
  getMeetingSchedule,
  getMeetingScheduleFailure,
  getMeetingScheduleSuccess,
} from "./AddMeetingScheduleSlice";

// get all meeting schedule
function* onGetMeetingSchedule({ payload: { date = "", employeeId = "" } }) {
  try {
    const url = `/employee-panel/meeting-schedule/data/?date=${date}&employeeId=${employeeId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getMeetingScheduleSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get meeting schedule failed";
    yield put(getMeetingScheduleFailure({ message }));
  }
}

// add or update a meeting schedule
function* onAddMeetingSchedule({
  payload: {
    data,
    options: {
      setSubmitting,
      toggle,
      id,
      updateSchedule,
      meetingLink,
      removeItems,
    },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/meeting-schedule`
      : `/employee-panel/meeting-schedule/create`;
    let response;

    if (data?.length) {
      response = yield call(id ? patch : post, url, data);
    }

    if (updateSchedule) {
      updateSchedule?.forEach(async (el, idx) => {
        const url = `/employee-panel/meeting-schedule/${el?._id}`;

        await patch(url, { ...el, meetingLink });

        if (idx + 1 === updateSchedule?.length) {
          toggle(true);
        }
      });
    }

    if (response) {
      toaster(
        "success",
        `Meeting schedule ${id ? "Updated" : "Added"} Successfully`
      );
      // yield resetForm();
    }

    if (removeItems?.length) {
      removeItems?.forEach(async (el, idx) => {
        const url = `/employee-panel/meeting-schedule/${el?._id}`;
        await del(url);

        if (idx + 1 === removeItems?.length) {
          toggle(true);
          if (!response) {
            toaster("success", `Meeting schedule updated successfully`);
          }
        }
      });
    } else {
      yield toggle(true);
    }

    yield setSubmitting(false);
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Meeting schedule ${id ? "Update" : "Add"} Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

// delete a meeting schedule
function* onDeleteMeetingSchedule({ payload: { id, toggle } }) {
  try {
    const url = `/employee-panel/meeting-schedule/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteMeetingScheduleSuccess(response));
      toaster("success", "Meeting schedule deleted successfully");
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Meeting schedule deleted failed";
    toaster("error", message);
    yield put(deleteMeetingScheduleFailure({ message }));
  }
}

export function* addMeetingScheduleSaga() {
  yield takeEvery(getMeetingSchedule.type, onGetMeetingSchedule);
  yield takeEvery(addMeetingSchedule.type, onAddMeetingSchedule);
  yield takeEvery(deleteMeetingSchedule.type, onDeleteMeetingSchedule);
}
