import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  department: {},
  singleDepartment: {},
  designation: {},
  feedbackForm: {},
  feedbackCount: {},
  error: "",
  adding: false,
  feedbackLoading: false,
};

const islamGroupSlice = createSlice({
  name: "islamGroup",
  initialState,
  reducers: {
    getIGDepartment: (state) => {
      state.loading = true;
    },
    getIGDepartmentSuccess: (state, action) => {
      state.loading = false;
      state.department = action.payload;
    },
    getIGDepartmentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    getIGSingleDepartment: (state) => {
      state.loading = true;
    },
    getIGSingleDepartmentSuccess: (state, action) => {
      state.loading = false;
      state.singleDepartment = action.payload;
    },
    getIGSingleDepartmentFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    addIGDepartment: (state, action) => {},
    deleteIGDepartment: (state, action) => {
      state.adding = true;
    },
    deleteIGDepartmentSuccess: (state, action) => {
      state.adding = false;
    },
    deleteIGDepartmentFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    getIGDesignation: (state) => {
      state.loading = true;
    },
    getIGDesignationSuccess: (state, action) => {
      state.loading = false;
      state.designation = action.payload;
    },
    getIGDesignationFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
    addIGDesignation: (state, action) => {},
    deleteIGDesignation: (state, action) => {
      state.adding = true;
    },
    deleteIGDesignationSuccess: (state, action) => {
      state.adding = false;
    },
    deleteIGDesignationFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    addFeedback: (state, action) => {
      state.feedbackLoading = true;
    },
    addFeedbackSuccess: (state, action) => {
      state.feedbackLoading = false;
    },
    addFeedbackFailure: (state, action) => {
      state.error = action.payload.message;
      state.feedbackLoading = false;
    },
    getFeedbackFormData: (state) => {
      state.loading = true;
    },
    getFeedbackFormDataSuccess: (state, action) => {
      state.loading = false;
      state.feedbackForm = action.payload;
    },
    getFeedbackFormDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
      state.feedbackForm = {};
    },
    getFeedbackFormCount: (state) => {
      state.loading = true;
    },
    getFeedbackFormCountSuccess: (state, action) => {
      state.loading = false;
      state.feedbackCount = action.payload;
    },
    getFeedbackFormCountFail: (state, action) => {
      state.loading = false;
      state.error = action.payload?.message;
    },
  },
});

export const {
  getIGDepartment,
  getIGDepartmentSuccess,
  getIGDepartmentFail,
  getIGSingleDepartment,
  getIGSingleDepartmentSuccess,
  getIGSingleDepartmentFail,
  addIGDepartment,
  getIGDesignation,
  getIGDesignationSuccess,
  getIGDesignationFail,
  addIGDesignation,
  deleteIGDesignation,
  deleteIGDesignationSuccess,
  deleteIGDesignationFailure,
  deleteIGDepartment,
  deleteIGDepartmentSuccess,
  deleteIGDepartmentFailure,
  addFeedback,
  addFeedbackSuccess,
  addFeedbackFailure,
  getFeedbackFormData,
  getFeedbackFormDataSuccess,
  getFeedbackFormDataFail,
  getFeedbackFormCount,
  getFeedbackFormCountSuccess,
  getFeedbackFormCountFail,
} = islamGroupSlice.actions;

export default islamGroupSlice.reducer;
