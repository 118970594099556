import { configureStore } from "@reduxjs/toolkit";
import persistStore from "redux-persist/es/persistStore";
import createSagaMiddleware from "redux-saga";
import { rootReducers } from "./rootReducers";
import sagas from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// sagaMiddleware: Makes redux-sagas work
const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: { ...rootReducers },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    ...middleware,
  ],
  devTools: process.env.NODE_ENV !== "production",
});

store.__persistor = persistStore(store);

sagaMiddleware.run(sagas);

export default store;
